// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from 'react-markdown'

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar/navBar"

function WhatToExpectCavingPage() {
  const { strapiWhatToExpectOnACavingTrip } = useStaticQuery(graphql`
  {
    strapiWhatToExpectOnACavingTrip {
      description {
        data {
          description
        }
      }
    }
  }`)

  return (
    <Layout>
      <Seo title="What to expect on a caving trip" />
      <NavBar />
    <ReactMarkdown>{strapiWhatToExpectOnACavingTrip.description.data.description}</ReactMarkdown>
    </Layout>
  )
} 

export default WhatToExpectCavingPage
